import { Dispatch } from 'react';
import Enc from '@root/encoding/base64';
import { FormId } from 'interfaces/common-interfaces';
import { Answers } from 'interfaces/answers-interfaces';
import { SetLandingId } from 'pages/mip/hrt/hormone-assessment/ha-reducer';
import { Page, Question } from 'services/memo/questions-response';
import { ClassNameMap } from '@material-ui/styles';
import {
  MemoAnswer,
  Answer,
  FormAnswers,
  CombinedMemoAnswerQuestion,
  AnswersObj,
} from './utils-interfaces';

/**
 * Checks if a choice is in values.
 * @param {object | string} values The current form values.
 * @param {string} choice The value to check for.
 * @returns {boolean}
 */
export function checked(
  values: Record<string, unknown> | string,
  choice: string
): boolean {
  if (Array.isArray(values)) {
    if (values.includes(choice)) {
      return true;
    }
  } else if (values === choice) {
    return true;
  }
  return false;
}

/**
 * Formats all keys in an object to a query string.
 * @param {object} params An object of parameters
 * @returns {string} A formatted query string
 */
export function encodeQueryString(params: Record<string, unknown>): string {
  const remapped = Object.entries(params).map(
    ([key, value]) => `${key}=${value}`
  );
  const joined = remapped.join('&');
  return joined ? `?${joined}` : '';
}

/**
 * Transforms a query string into an object
 * @param {string} queryString A properly formatted query string
 * @returns {object} An object containing all keys and values from the input string
 */
export function decodeQueryString(string: string): Record<string, string> {
  return string
    .slice(1)
    .split('&')
    .reduce((acc, val) => {
      let key = '';
      let value = '';
      try {
        const pair = decodeURIComponent(val);
        [key, value] = pair.split('=');
      } catch (err) {
        console.error(err);
      }
      if (!key) return acc;
      return { ...acc, ...{ [key]: value } };
    }, {});
}

/**
 * Generates a base64 encoded landing id
 *
 * @param lastName Patient's last name
 * @param email Patient's email
 * @param formId Patient's formId
 * @returns {string}
 */
export const generateLandingId = (
  lastName: string,
  email: string,
  formId: FormId,
  landedAt?: string,
  suffix?: string
): string => {
  const currentDate = new Date();
  const isoString = currentDate.toISOString();
  const spacelessName = lastName ? lastName.trim().replace(/\s/g, '_') : '';
  const date = (landedAt ?? isoString).slice(0, 10);
  return Enc.strToUrlBase64(
    `${spacelessName}-${email}-${date}-${formId ?? 'MIP'}${suffix ?? ''}`
  );
};

/**
 * Sanitizes all form fields by type
 * @param {string|number} value - form value
 * @param {string} type - field type. Ex. 'short_text', 'date' and etc.
 */
export const sanitizeValue = (value: string | number, type: string) => {
  let sanitizedValue = Array.isArray(value) ? value : [value];
  if (type === 'date') {
    sanitizedValue = [value];
  }
  if (typeof value === 'string') {
    sanitizedValue = [value.replace(/^#+/g, '').trim()];
  }
  return sanitizedValue;
};

export const formatBirthday = (
  name: string,
  value: string | number
): string | number => {
  if (!['birthdate', 'birthdateH2'].includes(name)) return value;
  if (typeof value !== 'string') return value;
  const [month, day, year] = value.split('/');
  return `${year}-${month}-${day}`;
};

export const simpleDate = (date: string) => {
  const [year, month, day] = new Date(date)
    .toISOString()
    .slice(0, 10)
    .split('-');
  return `${month}/${day}/${year}`;
};

/**
 * ! DEPRECATED
 * Combines the responses from both the pageQuestions and
 * answers API into an array of objects that can be
 * passed to checkCustQualifications.
 * Created by Nanda
 * @param {object} questionsResp The response from the /pageQuestions API
 * @param {object} answersResp The response from the /answers API
 */
// export function combineAnswersAndQuestions(
//   questionsResp: QuestionsResponse,
//   answersResp: AnswersResponse
// ) {
//   const { pages } = questionsResp;
//   const { answers } = answersResp;

//   // Creates an object with only the answers we need.
//   // The keys listed in the array will be the keys returned in the object.
//   const filteredAnswers = Object.keys(answers)
//     .filter((arr) => ['firstName', 'lastName', 'email', 'phone'].includes(arr))
//     .reduce((obj, key) => ({ [key]: answers[key] }), {});

//   // A huge and complicated filter and mapping sequence.
//   // Returns an array of objects with the appropriate
//   // keys and values that checkCustQualifications expects.
//   const questionNeedles = pages
//     .filter((val) =>
//       ['firstName', 'lastName', 'email', 'phone'].includes(
//         val.questions[0].name
//       )
//     )
//     .map((val) => {
//       // Filters and creates an object with the appropriate keys and values.
//       // The keys listed in the array will be the
//       // keys returned in each object. Some keys needed to be renamed.
//       const finished: QuestionNeedle = Object.keys(val.questions[0])
//         .filter((key) =>
//           ['pageId', 'ref', 'label', 'type', 'questionId'].includes(key)
//         )
//         .reduce((obj, key) => {
//           const newObj: QuestionNeedle = {};
//           if (key === 'pageId') {
//             newObj.questionNumber = val.questions[0][key];
//           } else if (key === 'label') {
//             newObj.title = val.questions[0][key];
//           } else {
//             newObj[key] = val.questions[0][key];
//           }
//           return newObj;
//         }, {});

//       finished.value = [filteredAnswers[val.questions[0].name]];
//       return finished;
//     });

//   return questionNeedles;
// }

/**
 * This function picks specific values from the answers API and transforms them
 * into an object array with the required keys and format.
 * Picked values are: firstName, lastName, email, phone
 * Returned values are: ref, type, value
 * @param obj An object of answers from /getAnswers
 * @returns An array of objects that is required by checkCustQualifications
 */
export function checkCustQualPrep(obj: AnswersObj): MemoAnswer[] {
  const { firstName, lastName, email, phone } = obj;
  const filteredAnswers = {
    firstName,
    lastName,
    email,
    phone,
  };

  // Validates that the value for the keys actually exists.
  Object.entries(filteredAnswers).forEach(([k, v]) => {
    if (!v) {
      throw new Error(`No value found for key ${k} in given object.`);
    }
  });

  return Object.entries(filteredAnswers).map(([k, v]) => ({
    ref: k,
    value: [v],
    type: 'This key is required but not actually validated',
  }));
}

/**
 * Converts an ISO formatted string to a more human readable format.
 * e.g. Februrary 31, 1970
 * @param {string} ISOString An ISO formatted string
 */
export function humanReadableDate(ISOString: string) {
  return new Date(ISOString).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
}

/**
 * Returns the name of the endpoint from a URL.
 * - Nanda
 * e.g. https://myapi.com/myEndpoint?foo=bar -> myEndpoint
 * @param {string} URLString A full, complete URL string.
 * @returns {string} The name of the endpoint
 */
export function endpointFromURL(URLString: string): string {
  const splitSlash = URLString.split('/');
  const popped = splitSlash.pop() as string;
  const splitQ = popped.split('?');
  return splitQ.slice()[0];
}

/**
 * A simple function that takes a date string and returns an ISO formatted date.
 * - Nanda
 * @param {string} dateParseableString A string that can be parsed by Date
 * @returns {string} A string like 2020-10-30
 */
export function getDate(dateParseableString: string): string {
  try {
    return new Date(dateParseableString).toISOString().slice(0, 10);
  } catch (error) {
    console.error(error);
    return dateParseableString;
  }
}

/**
 * Takes an object of answers and splits each answer into
 * an array of objects. Each object has the answer name and value.
 *
 * @param {object} answers The answers supplied by the form
 * @returns {array} An array of each individual answer and its value
 */
export function splitAnswers(answers: Record<string, unknown>): Answer[] {
  return Object.entries(answers).map(([key, value]) => ({
    name: key,
    value: Array.isArray(value) ? value : [value],
  }));
}

/**
 * Sorts an array of objects by value from the given key
 *
 * @param {object[]} arr The array to sort
 * @param {string} key The key to sort by
 * @returns A sorted array
 */
export function sortByKey<T extends Record<string, unknown>>(
  arr: T[],
  key: keyof T
): T[] {
  return arr.sort((a: T, b: T) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
}

/**
 * Takes an array of answer objects and renames certain keys
 * for compatability with the Memo API
 *
 * @param {array} arr Array of answer objects
 * @returns {array} Array of answers Memo expects
 */
export function reformatAnswers(arr: Answer[]): MemoAnswer[] {
  return arr.map((answer: Answer) => ({
    ref: answer.name,
    type: undefined,
    value: Array.isArray(answer.value)
      ? (answer.value as string[])
      : [answer.value as string],
  }));
}

/**
 * Combines both arrays into a single array with each object
 * sharing properties.
 *
 * ! This function uses object spreading, meaning that shared keys
 * ! in the questions array will overwrite the keys in the answers array
 *
 * @param answers Answers that Memo expects
 * @param questions Array of questions
 * @returns An object array with all common keys
 */
export function combineObjectArrays(
  answers: MemoAnswer[],
  questions: Question[]
): CombinedMemoAnswerQuestion[] {
  if (answers.length !== questions.length) {
    throw new Error(
      `Arrays given are not of equal length. arr1: ${answers.length} arr2: ${questions.length}`
    );
  }

  // Create an output array of length equal to the given arrays
  const output = Array(answers.length).fill(undefined);

  return output.map((val: Record<string, unknown>, i: number) => {
    const arr1Item = answers[i];
    const arr2Item = questions[i];

    if (arr1Item.ref !== arr2Item.ref) {
      throw new Error('Array refs are not equal. Did you try sorting first?');
    }

    return { ...arr1Item, ...arr2Item };
  });
}

/**
 * Cleans the output of combined array
 *
 * @param arr An array with at least ref, type, value
 * @returns An array of objects with ref, type, value
 */
export function sanitizeOutput(
  arr: CombinedMemoAnswerQuestion[]
): MemoAnswer[] {
  return arr.map((val: CombinedMemoAnswerQuestion) => {
    const { ref, type, value } = val;
    return { ref, type, value };
  });
}

/**
 * Takes an object of form answers and formats them to send to
 * Memo for recording
 *
 * @param answers An object with form answers
 * @param questions An array of questions from Memo
 * @returns An array ready for saveLead
 */
export function prepareQuestions(
  answers: Record<string, unknown>,
  questions: Question[]
): MemoAnswer[] {
  const remappedQuestions: MemoAnswer[] = [];
  questions.forEach((question) => {
    const { name, ref, type, questionId } = question;
    const formValue = answers[name] as string | number;
    if (formValue) {
      const birthdateFormat = formatBirthday(name, formValue);
      const value = sanitizeValue(birthdateFormat, type);
      remappedQuestions.push({
        ref,
        type,
        value,
        questionId,
      });
    }
  });
  return remappedQuestions;
}

/**
 * Creates and configures and object to send to saveLead based on the requirements
 * for the hormone assessment page.
 * @param values The form values
 * @param questions The list of questions for the form
 * @param landedAt A date parseable string
 * @param setLandingId A dispatch function for updating the landing id
 * @returns An object ready to send to saveLead
 */
export function hormoneAssessmentLeadMapper(
  values: FormAnswers,
  questions: Question[],
  landedAt: string,
  dispatch: Dispatch<SetLandingId>
) {
  const answers = prepareQuestions(values, questions);
  const landingId =
    (values.landing_id as string) ||
    (values.landingId as string) ||
    generateLandingId(
      values.lastName as string,
      values.email as string,
      'MIP-H2'
    );
  dispatch({ type: 'set-landing-id', landingId });
  return {
    landing_id: landingId,
    landed_at: landedAt,
    answers,
    submitted_at: new Date().toISOString(),
  };
}

/**
 * Creates and configures and object to send to saveLead based on the requirements
 * for the intake and continious pages.
 * @param values The form values
 * @param questions The list of questions for the form
 * @param landedAt A date parseable string
 * @param queryParams parameters taken from the uri
 * @returns An object ready to send to saveLead
 */
export function intakeLeadMapper(
  values: Record<string, unknown>,
  pages: Page[],
  landedAt: string,
  queryParams: Record<string, string>,
  lastLandingId?: string
) {
  const questions: Question[] = [];
  const questionRefs: Set<string> = new Set();
  pages.forEach((page) => {
    page.questions.forEach((question) => {
      if (!questionRefs.has(question.ref)) {
        questions.push(question);
        questionRefs.add(question.ref);
      }
    });
  });
  const answers = prepareQuestions(values, questions);
  let landingId =
    (values.landing_id as string) ||
    (values.landingId as string) ||
    generateLandingId(
      values.lastName as string,
      values.email as string,
      queryParams?.formId as FormId,
      landedAt
    );
  if (landingId === lastLandingId) {
    landingId = generateLandingId(
      values.lastName as string,
      values.email as string,
      queryParams?.formId as FormId,
      landedAt,
      'b'
    );
  }
  return {
    landing_id: landingId,
    landed_at: landedAt,
    answers,
    submitted_at: new Date().toISOString(),
  };
}

/**
 * Slices a string into two parts.
 * @param string Any string
 * @param index An index in that string
 * @returns An array with the two halves of the string
 */
export function sliceInTwain(string: string, index: number): string[] {
  return [string.slice(0, index), string.slice(index)];
}

/**
 * Removes a value from an object based on the key.
 * Always returns a new object and never modified the original.
 * @param object The object to work with
 * @param keyName A key that exists on the object
 * @returns A new object with the removed key
 */
export function removeFromObject<T>(object: T, keyName: keyof T): T {
  if (!object[keyName]) {
    return object;
  }
  const copy = { ...object };
  delete copy[keyName];
  return copy;
}

/**
 * Searches an array of objects for a key that matches a value.
 * @param arr An array of objects
 * @param key A common key of each object
 * @param value The value to search for
 * @returns {object} If only one result exists
 * @returns {object[]} If multiple results exist
 */
export function getByKey<T extends Record<string, unknown>>(
  arr: T[],
  key: keyof T,
  value: string
): T | T[] {
  const search = arr.filter((obj) => obj[key] === value);
  if (search.length === 1) {
    return search[0];
  }

  return search;
}

/**
 * Split an array into two smaller arrays based on a given index.
 * Does not modify the original array.
 * @param array An array of things
 * @param index An index to split by
 * @returns An array of two smaller arrays
 */
export function splitArray<T>(array: T[], index: number) {
  const copy = [...array];
  const a = copy.slice(0, index);
  const b = copy.slice(index);
  return [a, b];
}

/**
 * unmaskPhone
 * Remove masking formatting from phone value and return as string
 * @param maskedPhone phone value in (xxx) yyy-zzzz format
 * @returns a string of digits
 */
export function unmaskPhone(maskedPhone: string): string {
  if (!maskedPhone) {
    return '';
  }
  const unmaskedArray = maskedPhone.match(/\d+/g);
  if (!unmaskedArray) return '';
  return unmaskedArray.join('');
}

/**
 * classCombiner
 * For combining several makeStyles classes object properties to use together as a list
 * of classNames for an element.
 * @param classObj makeStyles classes ClassNameMap object
 * @returns function that takes in unlimited string arguments returns string of space
 * joined classNames.
 */
export function classCombiner<T extends ClassNameMap>(classObj: T) {
  type argType = keyof typeof classObj | string[];
  return (...args: argType[]) => {
    const classNames: string[] = [];
    args.forEach((arg) => {
      if (Array.isArray(arg)) {
        arg.forEach((outsideClass) => {
          classNames.push(outsideClass);
        });
      } else {
        classNames.push(classObj[arg]);
      }
    });
    return classNames.join(' ');
  };
}

/**
 * Reduces an array of objects to a single object.
 * Each key is a common key among the objects supplied.
 * Each value is the original object
 * @param array An array of objects
 * @param key A common key among all objects
 * @returns An object
 */
export function arrayToObject<T extends Record<string, unknown>>(
  array: T[],
  key: keyof T
): Record<string, T> {
  return array
    .map((val) => ({ [val[key] as Extract<typeof key, string>]: val }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});
}

/**
 * dateDaysAway - returns a date string a specified number of days from
 * the date input paramter. if no date param provided,
 * @param daysAway number, diffence from start date
 * @param startState optional date string, if not present will use current date
 * @returns A 10-digit date string
 */
export function dateDaysAway(daysAway: number, startDate?: string): string {
  const result = startDate ? new Date(startDate) : new Date();
  const currentDate = result.getDate();
  result.setDate(currentDate + daysAway);
  return result.toISOString().slice(0, 10);
}

/**
 * Includes the DL# in the returned questions based on shipState
 * @param questions Question list from getQuestions
 * @param shipState The shipping state of the order
 * @returns An array of questions with or without the DL# questions
 */
export function filterIdQuestions(questions: Question[], shipState: string) {
  const needIdStates: string[] = [
    'AL',
    'HI',
    'IL',
    'IN',
    'KS',
    'MA',
    'MI',
    'NC',
    'ND',
    'OK',
    'UT',
    'KY'
  ];

  const needSSNStates: string[] = [
    'KY'
  ];

  const needsId = needIdStates.includes(shipState);
  const needsSSN = needSSNStates.includes(shipState);

  if (needsId && needsSSN) {
    return questions;
  }

  if (needsId) {
    return questions.filter((val) => val.questionId !== 'ssn');
  }

  return questions.filter((val) => !['idType', 'idNumber', 'ssn'].includes(val.questionId));
}

/**
 * Calculates the age of a person from the supplied date
 * @param birthday A Date parseable string
 * @returns {number} The age of the user
 */
export function calculateAge(birthday: string): number {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return ageDate.getUTCFullYear() - 1970;
}

export function getAgeGroup(birthdate: string): string {
  const age = calculateAge(birthdate);

  if (age >= 38) {
    return '38 Plus';
  }

  if (age < 30) {
    return '18 - 29';
  }

  return '30 - 37';
}

export function getGrandTotal(
  total: number,
  discount: number,
  shipping: number
) {
  const discountApplied = total - discount;
  const preShipping = discount ? discountApplied : total;
  const grandTotal = preShipping + shipping;

  return grandTotal;
}

/**
 * expandedTableTo1Value
 * Turns multiple answer values from FormExpandedTable input into a single value
 * @param {Answers} answers The current form values.
 * @param {string[]} columnList list of column names passed as options to FormExpandedTable
 * @param {string} parentQuestioNAme name of Question passed to FormExpandedTable
 * @returns {string}
 */
export function expandedTableTo1Value(
  answers: Answers,
  columnList: string[],
  parentQuestionName: string
) {
  const rowNumbers = answers[parentQuestionName] as string[];
  return rowNumbers.map((rowNumber) =>
    columnList
      .map(
        (column) =>
          `${column}: ${answers[`${parentQuestionName}_${rowNumber}_${column}`]
          }`
      )
      .join(', ')
  );
}

/**
 * Returns branding information for male and female brands.
 * @returns {object} { isFemme, isMale, brandName }
 */
export function getBrandInfo(): {
  isFemme: boolean;
  isMale: boolean;
  brandName: 'Male Excel' | 'Fem Excel';
  portalLink: string;
} {
  const isFemme =
    process.env.REACT_APP_FEMME === 'true' ||
    window.location.hostname.includes('femexcelle.com') ||
    window.location.hostname.includes('femexcel.com');
  const isMale = !isFemme;
  const brandName = isFemme ? 'Fem Excel' : 'Male Excel';
  const portalURL = process.env.REACT_APP_PATIENT_PORTAL_URL ?? ''
  const portalLink = isFemme ? portalURL.replace('maleexcel.com', 'femexcel.com') : portalURL;
  return { isFemme, isMale, brandName, portalLink };
}

export function expireDateToMMYY(dateString) {
  if (!dateString) return null;
  try {
    const date = new Date(dateString);
    const mmYY = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      year: '2-digit',
    });
    return mmYY.format(date);
  } catch (err) {
    console.error(err);
    return null;
  }
}
export function isPRODCheck(): boolean {
  return [
    'www.maleexcelmip.com',
    'go.femexcelle.com',
    'go.femexcel.com',
    'go.maleexcel.com',
  ].includes(window.location.hostname);
}

/**
 * calculateTotalScores
 * Calculate all the scores and returns a total based on the scores deficiency and excess
 * @param {scores} scores The current scores values.
 * @returns {number}
 */
export function calculateTotalScores(scores): number {
  let result = 0;

  Object.keys(scores).forEach((key) => {
    result = scores[key].deficiency + scores[key].excess;
  });

  return result;
}

// prettier-ignore
// eslint-disable-next-line
export const matchEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

export const matchPhoneRegex = /^([2-9]\d{2}[2-9]\d{2}\d{4})$/;

/**
 * isoStringDateOffset
 * get ISO string of date offset by argument, with optional startDate input
 * @param {number} offset number of days (positive or negative) to offset.
 * @param {Date=} startDate optional Date to use as starting point
 * @returns {string}
 */
export function isoStringDateOffset(offset: number, startDate?: Date): string {
  const start = startDate ?? new Date();
  const newDate = new Date(start);
  newDate.setDate(newDate.getDate() + offset);
  return newDate.toISOString();
}
